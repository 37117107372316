/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getFormsAction = createAction('PAA_GET_FORMS');
export const showFormAction = createAction('PAA_SHOW_FORMS');
export const createFormAction = createAction('PAA_CREATE_FORMS');
export const updateFormAction = createAction('PAA_UPDATE_FORMS');
export const deleteFormAction = createAction('PAA_DELETE_FORMS');
export const publishFormAction = createAction('PAA_PUBLISH_FORMS');
export const updateStatusFormAction = createAction('PAA_UPDATE_STATUS_FORMS');
export const resetFormPasswordAction = createAction('PAA_RESET_APP_FORMS_PASSWORD');
export const updateFormDateTimeAction = createAction('PAA_UPDATE_FOR_DATE_TIME');
export const copyFormAction = createAction('PAA_COPY_FORM');

export const getGroupAction = createAction('PAA_GET_GROUPS');
export const showGroupAction = createAction('PAA_SHOW_GROUP');
export const createGroupAction = createAction('PAA_CREATE_GROUP');
export const updateGroupAction = createAction('PAA_UPDATE_GROUP');
export const deleteGroupAction = createAction('PAA_DELETE_GROUP');
export const deleteGroupQuestionAction = createAction('PAA_DELETE_GROUP_QUESTION');

export const getQuestionBanksAction = createAction('PAA_GET_QUESTION_BANKS');

// question bank module
export const importQuestionBankAction = createAction('PAA_IMPORT_QUESTION_BANKS_LIST');
export const getQuestionBankAction = createAction('PAA_GET_QUESTION_BANKS_LIST');
export const showQuestionBankAction = createAction('PAA_SHOW_QUESTION_BANK');
export const createQuestionBankAction = createAction('PAA_CREATE_QUESTION_BANK');
export const createFormQuestionBankAction = createAction('PAA_CREATE_FORM_QUESTION_BANK');
export const updateQuestionBankAction = createAction('PAA_UPDATE_QUESTION_BANK');
export const deleteQuestionBankAction = createAction('PAA_DELETE_QUESTION_BANK');
export const getQuestionBankCategoryAction = createAction('PAA_GET_QUESTION_BANK_CATEGORIES');
export const updateQuestionBankCategoryAction = createAction('PAA_UPDATE_QUESTION_BANK_CATEGORIES');
export const validateChoiceConditionsAction = createAction('PAA_VALIDATE_CHOICE_CONDITIONS');
export const validateQuestionConditionsAction = createAction('PAA_VALIDATE_QUESTION _CONDITIONS');

export const setCustomFormAction = createAction('PAA_SET_CUSTOM_FORM');
export const addSidebarErrorsAction = createAction('PAA_ADD_SIDEBAR_ERRORS');
export const removeSidebarErrorsAction = createAction('PAA_REMOVE_SIDEBAR_ERRORS');
export const setPagesAction = createAction('PAA_SET_PAGES');
export const setQuestionsCountAction = createAction('PAA_SET_QUESTIONS_COUNT');

export const createPageAction = createAction('PAA_CREATE_PAGE_ACTION');
export const updatePageAction = createAction('PAA_UPDATE_PAGE_ACTION');
export const deletePageAction = createAction('PAA_DELETE_PAGE_ACTION');

export const createSectionAction = createAction('PAA_CREATE_SECTION_ACTION');
export const updateSectionAction = createAction('PAA_UPDATE_SECTION_ACTION');
export const deleteSectionAction = createAction('PAA_DELETE_SECTION_ACTION');

// Section
export const getSectionQuestionsAction = createAction('PAA_GET_SECTION_QUESTIONS');

// Question
export const createQuestionAction = createAction('PAA_CREATE_QUESTION_ACTION');
export const updateQuestionAction = createAction('PAA_UPDATE_QUESTION_ACTION');
export const deleteQuestionAction = createAction('PAA_DELETE_QUESTION_ACTION');
export const changeToQuestionBankAction = createAction('PAA_CHANGE_TO_QUESTION_BANK_ACTION');
export const updateQuestionOrderAction = createAction('PAA_UPDATE_QUESTION_ORDER_ACTION');
export const updateQuestionLocalConditionsAction = createAction('PAA_UPDATE_QUESTION_LOCAL_CONDITIONS_ACTION');
export const createOtherOptionAction = createAction('PAA_CREATE_OTHER_OPTION');
export const removeOtherOptionAction = createAction('PAA_REMOVE_OTHER_OPTION');
export const getFormTotalQuestionsAction = createAction('PAA_GET_FORM_TOTAL_QUESTIONS');

// Group
export const importQuestionGroupAction = createAction('PAA_IMPORT_QUESTION_GROUP_ACITON');


// Choices
export const createQuestionChoiceAction = createAction('PAA_CREATE_QUESTION_CHOICE_ACTION');
export const updateQuestionChoiceAction = createAction('PAA_UPDATE_QUESTION_CHOICE_ACTION');
export const deleteQuestionChoiceAction = createAction('PAA_DELETE_QUESTION_CHOICE_ACTION');
export const bulkUpdateQuestionChoiceAction = createAction('PAA_BULK_UPDATE_QUESTION_CHOICE_ACTION');
export const sortQuestionChoicesAction = createAction('PAA_SORT_QUESTION_CHOICES');

export const updateFormNameAction = createAction('PAA_UPDATE_FORM_NAME');
export const updateFormGroupNameAction = createAction('PAA_UPDATE_FORM_GROUP_NAME');

export const getGlobalConditionsAction = createAction('PAA_GET_GLOBAL_CONDITIONS_ACTION');
export const showQuestionGlobalConditionsAction = createAction('PAA_SHOW_QUESTION_GLOBAL_CONDITIONS_ACTION');
export const createQuestionGlobalConditionsAction = createAction('PAA_CREATE_QUESTION_GLOBAL_CONDITIONS_ACTION');
export const updateQuestionGlobalConditionsAction = createAction('PAA_UPDATE_QUESTION_GLOBAL_CONDITIONS_ACTION');
export const deleteQuestionGlobalConditionsAction = createAction('PAA_DELETE_QUESTION_GLOBAL_CONDITIONS_ACTION');

export const getConditionsErrorLogsAction = createAction('PAA_GET_CONDITIONS_ERROR_LOGS_ACTION');

export const savingAction = createAction('PAA_SAVING_ACTION');

// Responses
export const getFormResponseAction = createAction('PAA_GET_FORM_RESPONSE');
export const downloadFormResponseAction = createAction('PAA_DOWNLOAD_FORM_RESPONSE');
export const getGeneratePDFFileResponseAction = createAction('PAA_GET_GENERATED_PDF_FILE');
export const updateFormResponseEditabilityAction = createAction('PAA_UPDATE_FORM_RESPONSE_EDITABILITY');

export const getGroupFormAction = createAction('PAA_GET_GROUP_FORMS');
export const showGroupFormAction = createAction('PAA_SHOW_GROUP_FORM');

export const getPublishedStatusFormAction = createAction('PAA_GET_PUBLISHED_STATUS_FORMS');
